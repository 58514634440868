/* Heading container */
.heading{ 
  padding-bottom: 2rem; 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; 
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center; 
}

/* Heading sub-title */ 
.heading span{
  letter-spacing: 0.2rem;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--main-color);
}

/* Heading title */
.heading h2{   
  font-size: 4rem;  
  font-weight: 600;
  color: var(--secondary-color);
  letter-spacing: 0.1rem;
  line-height: 1.2;
  padding-bottom: 0.5rem;
} 

/* Heading Image */
.heading img{
  height: 1.5rem;
  object-fit: cover;
}

/* Override styles for Heading title, Within a linear background container */
.linear-bg .heading h2{
  color: var(--white);
}