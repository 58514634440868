/* Home Section */
.home {
  padding: 0;
  height: 1px;
  min-height: 100vh;
  position: relative; 
} 

/* Home Slider */ 
.home-slider { 
  position: relative; 
} 
 
/* Home Slider Navigation Buttons */
.home-slider .swiper-button-next,
.home-slider .swiper-button-prev {
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1rem;
  height: 5rem;
  width: 5rem;
  padding: 2rem;
  opacity: 0;
}

/* Home Slider Navigation Buttons, On Home Slider Hover */
.home-slider:hover .swiper-button-next,
.home-slider:hover .swiper-button-prev{
    opacity: 1;
}

/* Home Slider Navigation Buttons, Hover Effect */
.home-slider .swiper-button-next:hover,
.home-slider .swiper-button-prev:hover {
  background-color: var(--secondary-color);
}

/* Home Slider Navigation Buttons Icon */
.home-slider .swiper-button-next::after,
.home-slider .swiper-button-prev::after {
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center; 
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Home Item */
.home-item {
  min-height: 100vh;
}

/* Home Item Image */
.home-item img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

/* Home Item Content */
.home-item .content {
  padding: 2rem 5%;
  padding-top: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  background:         linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
}

/* Home Item Content Text */
.home-item .content .text {
  width: 60rem;
  text-align: left;
}

/* Home Welcome Message */
.home-item .content h5{
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: var(--main-color);
}

/* Home Item Content Heading */
.home-item .content h3 {
  font-size: 5rem;
  font-weight: 550;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: var(--white);
}

/* Home Item Content Paragraph */
.home-item .content p{
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--white);
  padding-bottom: 2rem;
  line-height: 1.4;
}