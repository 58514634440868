/* Team Member Information Container */
.team-single .box-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 3rem; 
}  
  
/* Team Member Introduction */
.team-single .intro{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 15rem; 
      flex: 1 1 15rem;
} 

/* Team Member Image Container */
.team-single .image{  
  height: 45rem;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

/* Team Member Image */
.team-single .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Social media icons */
.team-single .icon-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem; 
  gap: 0.7rem;
}

/* Social media link */
.team-single .icon-container a{
  height: 4rem;
  width: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  font-size: 1.5rem;
  color: var(--white);
  background-color: var(--main-color);
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

/* Social media link, On Hover */
.team-single.icon-container a:hover{
  background-color: var(--secondary-color);
}

/* Team Member Information */
.team-single .information{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50rem;
      flex: 1 1 50rem;
}

/* Team Member Name */
.team-single .information h3{
  color: var(--black);
  font-size: 3rem;
  font-weight: 600;
}

/* Team Member Title */
.team-single .information p{
  color: var(--main-color);
  font-size: 1.8rem;
  font-weight: 500;
  padding-bottom: 1.5rem;
}

/* Team Member About */
.team-single .information .team-about{
  padding-top: 1.5rem;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
}

/* Biography Title */
.team-single h4{
  font-size: 3rem;
  font-weight: 500;
  color: var(--black);
  padding-bottom: 0.5rem;
}

/* Team Member Biography */
.team-single .team-about .text{
  font-size: 1.6rem;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: var(--grey);
  line-height: 1.7;
}

/* Skills Container */
.team-single .skills{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

/* Set of skills */
.team-single .skill-item{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 30rem;
      flex: 1 1 30rem; 
}

/* skill Box */
.team-single .skill-item .box{
  padding: 1rem 0;
}

/* Skill title and progress percentage */
.team-single .skill-item .box h3{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.7rem 0;
  font-size: 1.7rem;
  color: var(--black);
  font-weight: 400;
}

/* Progress bar */
.team-single .skill-item .box .progress-bar{
  width: 100%;
  background: var(--secondary-color);
  overflow: hidden;
  height: 1.2rem;
  border-radius: 50rem;
}

/* Skill Progress bar */
.team-single .skill-item .box .progress-bar span{
  display: block;
  height: 100%;
  background: var(--main-color);
}