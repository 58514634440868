/* Project Single container */
.project-single{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 2rem; 
}   
 
/* Project Details */
.project-single .project-details{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25rem; 
      flex: 1 1 25rem;  
  padding: 4rem;
  background-color: var(--secondary-color);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

/* Project Sub Heading */
.project-single .project-details .sub-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--white);
  padding-bottom: 2.5rem;
  line-height: 1;
}

/* Project Detail Item */
.project-single .detail-item{
  padding-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

/* Project Detail Item Icon */
.project-single .detail-item .icon{
  height: 1.5rem;
  width: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  font-size: 1.2rem;
  color: var(--white);
  margin-right: 0.5rem;
}

/* Project Detail Item Content */
.project-single .detail-item .content{
  border-left: 0.15rem solid var(--white);
  padding-left: 1rem;
}

/* Project Detail Item Heading */
.project-single .detail-item h3{
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--white);
  line-height: 1;
  padding-bottom: 0.5rem;
}

/* Project Detail Item Paragraph */
.project-single .detail-item p{
  font-size: 1.6rem;
  font-weight: 400;
  color: #f7f7f7;
  line-height: 1;
}

/* Project Detail Item Paragraph (Website) */
.project-single .detail-item p.website{
  text-transform: none;
}

/* Project Info container */
.project-info{ 
  -webkit-box-flex: 1;  
  -ms-flex: 1 1 70rem;
      flex: 1 1 70rem;
}

/* Project Image Container */
.project-info .image{
  height: 40rem;
}

/* Project Image */
.project-info .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Project Content */
.project-info .content{
  padding-top: 2rem;
}

/* Project Main Heading */
.project-info .main-heading{
  display: inline-block;
  font-size: 4rem;
  font-weight: bold;
  color: var(--secondary-color);
  padding-bottom: 1rem;
}

/* Project Important Section */
.project-info .important{
  color: var(--black) !important;
  font-weight: 600;
}

/* Project Content Paragraph */
.project-info p{
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}

/* Project Gallery */
.project-info .project-gallery .box-container{
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

/* Project Gallery Image */
.project-info .project-gallery img{
  height: 25rem;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

/* Project Info - Sub Heading */
.project-info .sub-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--black);
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

/* Project Info - Unordered List */
.project-info ul{
  list-style: none;
}

/* Project Info - List Item */
.project-info ul li{
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--grey);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  gap: 1rem;
}

/* Project Info - List Item Icon */
.project-info ul .icon{ 
  color: var(--main-color);
  font-size: 1.2rem;
}