/* Button style */
.btn {
	position: relative;
	display: inline-block;
	border: 0.2rem solid var(--main-color);
	font-family: 'Lato', sans-serif;
	pointer-events: auto;
	cursor: pointer;  
	padding: 1.5rem 3rem;   
	font-size: 1.8rem;
	font-weight: 500;
	font-style: italic; 
    color: var(--white);
	background: var(--main-color);
	z-index: 1;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/* Button style, Pseudo-element */
/* This pseudo-element adds an additional visual effect to the button */
.btn:after{
	position: absolute;
	content: "";
	width: 0;
	height: 100%;
	top: 0;
	right: 0;
	z-index: -1;
	background: var(--secondary-color);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/* Button style, Pseudo-element, On Button hover */
.btn:hover:after{
	left: 0;
	width: 100%;
}