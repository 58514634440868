/* Footer Section */
.footer{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url("../../assets/Background/Footer.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url("../../assets/Background/Footer.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed; 
} 
 
/* Footer Box Container */ 
.footer .box-container{  
    display: -webkit-box;  
    display: -ms-flexbox;  
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 3rem;
    padding: 2rem 5%;
    border-bottom: 0.15rem solid rgba(255, 255, 255, 0.5);
}  

/* Footer Box */ 
.footer-item{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 22rem;
            flex: 1 1 22rem;
}

/* Footer First Box */ 
.footer-item:first-child{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33rem;
            flex: 1 1 33rem;
}

/* Footer Item Heading */
.footer-item .heading{ 
    -webkit-box-align: start; 
    -ms-flex-align: start; 
    -ms-grid-row-align: flex-start; 
    align-items: flex-start;
    padding-bottom: 1.5rem;
}

/* Footer Item Heading Title */
.footer-item .heading h2{ 
    color: var(--white);
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
}

/* Footer Item About Paragraph */
.footer-item > p{
    padding-top: 1rem;
    font-size: 1.5rem; 
} 

/* Footer Item Paragraph */
.footer-item p{
    color: var(--white); 
    font-size: 1.6rem;
    line-height: 1.6;
}

/* Footer Item Social container */
.footer-item .social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

/* Footer Item Social link */
.footer-item .social a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Footer Item Social Icon */
.footer-item .social a .icon{
    height: 4.5rem;
    width: 4.5rem;
    padding: 1.4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--white);
    background-color: var(--main-color);
    font-size: 1.5rem;
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

/* Footer Item Social Icon, On Social Link Hover */
.footer-item .social a:hover .icon{
    background-color: var(--secondary-color);
}

/* Footer Item Info container Paragraph */
.footer-item .info p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* Footer Item Info container Link */
.footer-item .info a{ 
    font-size: 1.6rem;
    color: var(--white);
}

/* Footer Item Info container Link, Hover effect */
.footer-item .links p:hover a{
    color: var(--main-color);
}

/* Footer Item Info container Icon */
.footer-item .links p .icon{
    color: var(--main-color);
    font-size: 1.5rem;
}

/* Footer Item Connect continer Icon */
.footer-item .connect .icon{
    color: var(--main-color);
    height: 3rem;
    width: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Footer Item Connect container Text */
.footer-item .connect p{
    text-transform: none;
}

/* Footer Content */
.footer .content{
    text-align: center;
    padding: 2rem 0;
}

/* Footer Content Paragraph */
.footer .content p{
    font-size: 2rem;
    color: var(--white);
}

/* Footer Content Span */
.footer .content p span{
    color: var(--main-color);
}