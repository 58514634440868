/* Counting Section */
.counting {
  padding: 4rem 5%;
  margin: 1rem 0; 
  min-height: 15rem;  
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap; 
      flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center;
  gap: 4rem;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
  background:         linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/* Counting Box */
.counting .box {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 22rem;
      flex: 1 1 22rem; 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

/* Counting Box Icon */
.counting .box .icon {
  height: 10rem;
  width: 15rem;
  color: var(--white);
  background-color: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  font-size: 4rem;
  margin-bottom: 1rem;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

/* Counting Box Count */
.counting .box .count {
  font-size: 4rem;
  font-weight: 500;
  color: var(--white);
  line-height: 1;
}

/* Counting Box Heading */
.counting .box h3 {
  font-size: 2rem;
  font-weight: 500;
  color: var(--white);
}

/* Counting Box Line Style */
.counting .box .line{
  height: 0.2rem;
  width: 6rem;
  background-color: var(--main-color);
  margin-top: 1rem;
}