/* Aligning About content in the center */
.about .box-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 4rem;
  padding-top: 2rem;
} 

/* About images */
.about .image {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 40rem;
      flex: 1 1 40rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
  margin-bottom: 5rem;
  position: relative;
}

/* About First Image */
.about .sub-image1{
  height: 50rem;
  width: 85%;
  position: relative;
}

.about .sub-image1::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.about .sub-image1 img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* About Second Image */
.about .sub-image2{
  height: 40rem;
  width: 40rem;
  -webkit-transform: translate(-30rem, 5rem);
          transform: translate(-30rem, 5rem);
  margin-right: -30rem;
  position: relative;
}

.about .sub-image2 img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: 1rem solid var(--white);
}

/* About experience container */
.about .experience-container{
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(0 ,-47%);
          transform: translate(0 ,-47%);
  height: 17rem;
  width: 24rem;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  background-color: var(--white);
}

/* About experience content */
.about .experience{
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  font-size: 1.6rem;
  padding: 1rem 3.5rem;
  padding-bottom: 3rem;
  color: var(--white);
  background-color: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  line-height: 1.2;
}

/* About Experience Number */
.about .experience span{
  font-size: 4rem; 
  font-weight: 600; 
  line-height: 1.4; 
}

/* About content */
.about .content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 40rem;
      flex: 1 1 40rem;
}

/* About heading */
.about .heading{
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  padding-bottom: 1rem;
}

/* About Heading Title */
.about .content h3{
  font-size: 4rem;
  font-weight: 600;
  color: var(--black);
  line-height: 1.2;
  padding-bottom: 0.5rem;
}

/* About Paragraph style */
.about .content p{
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  font-weight: 450;
  color: var(--grey);
  line-height: 1.6;
  padding-bottom: 1rem;
}

/* About points list */
.about .about-points{
  list-style-type: none;
  padding-bottom: 2rem;
}

/* About points list item */
.about .about-points li{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

/* About points list item icon */
.about .about-points li .icon{
  height: 5rem;
  width: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 3.5rem;
  color: var(--main-color);
}

/* About points list item heading */
.about .about-points li h5{
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--secondary-color);
}

/* About points list item paragraph */
.about .about-points li p{
  font-size: 1.4rem;
  color: var(--grey);
}