/* Comment container */
.comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  gap: 2rem;  
  padding: 0.5rem 0;  
} 

/* Comment image container */
.comment .comment-image {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  border: 0.5rem solid var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Image inside the Comment image container */
.comment .comment-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
 
/* Comment content container */
.comment .comment-content { 
  -webkit-box-flex: 1;
  -ms-flex: 1 1 35rem;
      flex: 1 1 35rem;
}

/* Replies Comment content container */
.comments .replies .comment-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25rem;
      flex: 1 1 25rem;
}

/* Comment Details Container */
.comment .details{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding-bottom: 1rem;
}

/* Comment intro section */
.comment .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.7rem;
}

/* Author Name */
.comment .intro h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--black);
  line-height: 1;
}

/* Comment Date */
.comment .intro h6 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--white);
  background-color: var(--main-color);
  padding: 0.5rem 0.8rem;
}

/* Comment rating section */
.comment .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

/* Comment star icon */
.comment .rating .icon {
  font-size: 1.6rem;
  color: var(--main-color);
}

/* Comment rating value */
.comment .rating h5 {
  font-size: 1.6rem;
  color: var(--black);
}

/* Comment text */
.comment .text {
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
  padding-bottom: 1rem;
}

/* Comment Reply */
.comment .icon-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.3rem;
}

/* Comment Reply icon */
.comment .icon-item .icon {
  color: var(--main-color);
  font-size: 1.5rem;
}

/* Comment Reply text */
.comment .icon-item span {
  color: var(--grey);
  font-size: 1.6rem;
}

/* Comment Reply, Hover effect */
.comment .icon-item:hover .icon,
.comment .icon-item:hover span {
  cursor: pointer;
  color: var(--main-color);
}