/* Services Container*/
.services .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1rem;
} 
   
/* Service Card */   
.service-item{  
    text-align: center;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/* Service Card Image Container */
.service-item .image{
    position: relative;
    height: 20rem;
}

/* Service Card Image */
.service-item .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Service Card Icon */
.service-item .icon{
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    height: 8rem;
    width: 11rem;
    font-size: 3.5rem;
    color: var(--white);
    background-color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/* Service Card Content */
.service-item .content{
    padding: 2rem;
    padding-top: 5rem;
}

/* Service Heading */
.service-item h3{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 0.5rem;
}

/* Service Heading, On Hover */
.service-item h3:hover{
    color: var(--main-color);
}

/* Service Paragraph */
.service-item p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.7;
}