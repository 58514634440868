/* Appointment section */
.appointment{
  padding: 1rem 0;
} 

/* Appointment box container */
.appointment .box-container{
  width: 100%;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url("../../../assets//Background/Appointment.jpg");
  background:         linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url("../../../assets//Background/Appointment.jpg");
  background-position: center;
  background-size: cover;
  padding: 4rem 5%; 
} 

/* Appointment form container */
.appointment .box-container form{
  padding-top: 2rem;
}

/* Styling for input fields with autofill */
.appointment input:-webkit-autofill,
.appointment input:-webkit-autofill:hover,
.appointment input:-webkit-autofill:focus,
.appointment input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--white);
  -webkit-transition: background-color 5000s ease-in-out 0s;
          transition: background-color 5000s ease-in-out 0s;
} 

/* Styling for calendar picker indicator */
.appointment ::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
  filter: invert(1);
}

/* Input box container */
.appointment .input-box{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

/* Input Field inside Input box */
.appointment .input-box .box{
  width: 49.5%;
}

/* Input Field */
.appointment  .box{
  width: 100%;
  font-size: 1.6rem;
  color: var(--white);
  border: 0.1rem solid var(--white);
  background-color: transparent;
  text-transform: none;
  padding: 1.5rem 1rem;
}

/* Input Field, On Focused */
.appointment .appointment-form .box:focus{
  border-color: var(--main-color);
}

/* Input Field, Placeholder */
.appointment .appointment-form .box::-webkit-input-placeholder{
  text-transform: capitalize;
  color: var(--white);
}

.appointment .appointment-form .box::-moz-placeholder{
  text-transform: capitalize;
  color: var(--white);
}

.appointment .appointment-form .box:-ms-input-placeholder{
  text-transform: capitalize;
  color: var(--white);
}

.appointment .appointment-form .box::placeholder{
  text-transform: capitalize;
  color: var(--white);
}

/* Appointment form textarea */
.appointment .appointment-form textarea.box{
  height: 20rem;
  resize: none;
}

/* Appointment form button */
.appointment .appointment-form .btn{
  display: inline;
  margin-top: 1rem;
}

/* Appointment form alert */
.appointment .appointment-form .alert{
  font-size: 2rem;
  color: var(--white);
  padding-left: 1rem;
}