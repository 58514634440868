/* Page title Background styles */
.page-title{
  position: relative;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url("../../assets/PageTitle/PageTitle.jpg");
  background:         linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url("../../assets/PageTitle/PageTitle.jpg");
  min-height: 45rem;
  background-repeat: no-repeat;  
  background-size: cover; 
  background-position: center; 
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 5%;
  padding-top: 10rem;
}

/* Page title Container */
.page-title .container{
  border-left: 1rem solid var(--main-color);
  padding-left: 2rem;
}

/* Title text */
.page-title .title h2{
  color: var(--white);
  font-size: 5rem;
  font-weight: 600;
  text-transform: uppercase;
}

/* Homepage link */
.page-title .link{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.5rem;
  font-size: 1.8rem;
}

/* Homepage link icon */
.page-title .link a{
  font-weight: 600;
  color: var(--white);
}

/* Homepage link icon, On Hover */
.page-title .link a:hover{
  color: var(--main-color);
}

/* Angle Double Right icon */
.page-title .link .icon{
  color: var(--white);
}

/* Page name text */
.page-title .link span{
  font-weight: 500;
  color: var(--main-color);
} 