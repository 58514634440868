/* Pre-loader Container */
.pre-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;  
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 100000;
    -webkit-transition: none;
            transition: none;
}

/* Pre-loader Image */
.pre-loader img{
    height: 8rem;
    width: 8rem;
}

/* Loader Container */
.pre-loader #loader{
    display: block;
    position: relative;
    width: 20rem;
    height: 20rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Loader Before Animation */
.pre-loader #loader:before{
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--main-color);
    -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
}

/* Loader After Animation */
.pre-loader #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--main-color);
    -webkit-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
}

/* Spin Animation - Webkit Keyframes */
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/* Spin Animation - Standard Keyframes */
@keyframes spin {
    0%   { 
        -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}