/* Team member */
.team-item{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  position: relative;
  overflow: hidden;
  height: 40rem;
} 
  
/* Team member image container */
.team-item .image{
  height: 100%;
}
  
/* Team member image */
.team-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Social media icons Container */
.team-item .icon-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end; 
  gap: 0.7rem;
  position: absolute;
  top: 2rem;
  right: -100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  z-index: 2;
}

/* Social media icons Container, On Team Member Hover */
.team-item:hover .icon-container{
  right: 2rem;
}

/* Social media icon */
.team-item .icon-container a{
  height: 4rem;
  width: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  font-size: 1.5rem;
  color: var(--white);
  background-color: var(--main-color);
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

/* Social Media icon, On Hover */
.team-item .icon-container a:hover{
  background-color: var(--secondary-color);
}

/* Team member Content */
.team-item .content{
  padding: 2rem 1.5rem;
  text-align: center;
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  right: 0rem;
  background-color: var(--main-color);
}

/* Team member Name */
.team-item .content h2{
  color: var(--white);
  font-size: 2.5rem;
  font-weight: 600;
}
 
/* Team member Name, On Hover */
.team-item .content a h2:hover{
  color: var(--black);
}

/* Team member Title */
.team-item .content h5{
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 500;
}