/* Portfolio Tabs Button */
.projects .controls .button{
    padding: 1rem 2rem;
    font-size: 1.8rem;
    color: var(--white);
    background-color: var(--secondary-color);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}   
   
/* Portfolio Tabs Button, On Hover & Active */
.projects .controls .button:hover,
.projects .controls .button.active{
    cursor: pointer;
    background-color: var(--main-color);
}