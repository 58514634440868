/* Project Card */
.project-item{
    overflow: hidden;
    position: relative;
    height: 30rem;
}
  
/* Project Card Image */ 
.project-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;  
    overflow: hidden;
}

/* Project Card Content */
.project-item .content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 3rem;
    padding: 2rem;
    z-index: 1;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 1rem;
}

/* Project Category */
.project-item .content h6{
    font-size: 2rem;
    font-weight: 600;
    color: var(--main-color);
}

/* Project Title */
.project-item .content a{
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: var(--secondary-color);
}

/* Project Title, On Hover */
.project-item .content a:hover{
    color: var(--main-color);
}

/* Project Link Icon */
.project-item a .icon{
    height: 4rem;
    width: 4rem;
    font-size: 1.7rem;
    padding: 1rem;
    color: var(--white);
    background-color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2;
    border-radius: var(--border-radius);
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
}

/* Project Link Icon, On Hover */
.project-item a .icon:hover{
    cursor: pointer;
    background-color: var(--secondary-color);
}