/* Product Item inside Product Container (List) */
.shop .product-container.list .product-item{
    width: 100%;
    margin-bottom: 1rem;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 2rem;   
    padding: 0; 
}  
 
/* Product Item Image Container */
.shop .product-container.list .product-item .image{
    min-height: 25rem; 
    min-width: 25rem;
}

/* Product Item Image */
.shop .product-container.list .product-item .image img{
    height: 100%;
    width: 25rem;
    object-fit: cover;
}

/* Product Item Content */
.shop .product-container.list .product-item .content{
    text-align: left;
}

/* Product Item Content Paragraph */
.shop .product-container.list .product-item .content > p{
    font-size: 1.6rem;
    padding: 1rem 0;
    line-height: 1.5; 
}

/* Product Item Rating */
.shop .product-container.list .product-item .rating {
    justify-content: flex-start;
}  