@media (max-width: 991px) {

    /* Mobile Menu */
    .mobile-menu {
        display: block;
        position: absolute;
        top: 100%;
        right: 0; 
        left: 0; 
        z-index: 10000;
        max-height: 80vh; 
        overflow-y: auto; 
        background: var(--secondary-color);
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        -webkit-box-shadow: var(--box-shadow);
                box-shadow: var(--box-shadow);
        border-top: 0.2rem solid rgba(255, 255, 255, 0.2);
        border-bottom: 0.2rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    /* Active Mobile Menu */
    .mobile-menu.active {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    /* Mobile Menu Scrollbar */
    .mobile-menu::-webkit-scrollbar {
        width: 0.8rem;
    }

    .mobile-menu::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background-color: var(--main-color);
    }

    .mobile-menu::-webkit-scrollbar-track {
        background: transparent;
    }

}