/* Wishlist container */
.wishlist-container{
    overflow-x: auto;
    overflow-y: hidden; 
    border: var(--border);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
} 

/* Wishlist container Scrollbar */
.wishlist-container::-webkit-scrollbar{
    height: 1rem; 
}    

/* Wishlist Container */
.wishlist .container{
    min-width: 90rem;
}

.wishlist .box-container{
    padding: 0rem 1rem;
}

/* Wishlist item */
.wishlist-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0rem;
    gap: 3rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
} 

/* Wishlist item last child */
.wishlist-item:last-child{
    border: none;
}

/* Wishlist item Box */
.wishlist-item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    color: var(--black);
}

/* Wishlist item Product Container */
.wishlist-item .product{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem; 
        flex: 1 1 30rem;  
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    gap: 1rem;
}

/* Wishlist item Image */
.wishlist-item img{
    height: 8rem;
    width: 8rem;
    object-fit: cover;
    background-color:#f7f7f7;
}

/* Wishlist item Name */
.wishlist-item .name{
    color: var(--black);
    font-weight: 600;
}

/* Wishlist item Price */
.wishlist-item .price{
    color: var(--grey);
    font-weight: 500;
}

/* Wishlist item Action Container */
.wishlist-item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

/* Wishlist item Action Button */
.wishlist-item button{
    background-color: transparent; 
}

/* Wishlist item Icon */
.wishlist-item .icon{
    font-size: 2.5rem;
    cursor: pointer;
}

/* Remove item button */
.wishlist-item .delete{
    color: red;
}

/* Add To Cart button */
.wishlist-item .cart{
    color: var(--main-color);
}

/* Wishlist item Icon, Hover Effect */
.wishlist-item .icon:hover{
    color: var(--secondary-color); 
}