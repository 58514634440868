/* Faq accordion */
.faq .accordion {
    margin-bottom: 1.5rem;
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-left: 0.5rem solid var(--main-color);
    overflow: hidden;
}   
   
/* Faq last accordion */ 
.faq .accordion:last-child { 
    margin-bottom: 0rem;  
}      
  
/* Faq accordion heading */
.faq .accordion .accordion-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 1rem;
    color: var(--white);
    background-color: var(--secondary-color);
    cursor: pointer;
    overflow: hidden;
    min-height: 6rem;
}

/* Faq accordion heading title */
.faq .accordion .accordion-heading h3 {
    font-size: 2rem;
    padding: 1.5rem;
}

/* Faq accordion heading icon */
.faq .accordion .accordion-heading .icon {
    width: 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--white); 
    background-color: var(--main-color);
    font-size: 1.6rem;
}

/* Faq accordion content */
.faq .accordion-content {
    padding: 1.5rem;
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
    display: none;
}

/* Faq accordion content, On Accordion Expanded */
.faq .accordion.active .accordion-content {
    display: block;
}