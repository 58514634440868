/* Service Single container */
.service-single{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;  
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  gap: 2rem;
}   
 
/* Service Info container */
.service-info{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 75rem;
      flex: 1 1 75rem;
}

/* Service Image Container */
.service-info .image{
  height: 40rem; 
  overflow: hidden;
}

/* Service Image */
.service-info .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Service Main Heading */
.service-info .main-heading{
  display: inline-block;
  font-size: 4rem;
  font-weight: 600;
  color: var(--black);
  padding-top: 2rem;
  padding-bottom: 1rem;
}

/* Service Content Paragraph */
.service-info p{
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}

/* Service Gallery */
.service-info .service-gallery .box-container{
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

/* Service Gallery Image */
.service-info .service-gallery img{
  height: 25rem;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

/* Sub Heading */
.service-info .sub-heading{
  display: inline-block;
  font-size: 2.8rem;
  font-weight: 700;
  color: var(--black);
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
}

/* Service Info List */
.service-info ul{
  list-style: none;
}

/* Service Info List Item */
.service-info ul li{
  padding-bottom: 1rem;
  font-size: 1.6rem;
  color: var(--grey);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  gap: 1rem;
}

/* Service Info List Item Icon */
.service-info ul li .icon{ 
  color: var(--main-color);
  font-size: 1.4rem;
}

/* Service Workflow Container */
.service-info .service-workflow .box-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 2rem;
}

/* Work Item */
.service-info .work-item{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25rem; 
      flex: 1 1 25rem; 
  text-align: center;
  padding-top: 2rem;
}

/* Work Item Icon */
.service-info .work-item .icon{
  height: 8rem;
  width: 12rem;
  font-size: 3.5rem;
  padding: 2rem;
  color: var(--white);
  background-color: var(--secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
}

/* Work Item Heading */
.service-info .work-item h4{    
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--black);
  padding-bottom: 0.5rem;
}

/* Work Item Paragraph */
.service-info .work-item p{
  font-size: 1.4rem;
  color: var(--grey);
  line-height: 1.5;
}

/* Service FAQs */
.service-faqs{
  display: block;
}

/* Service FAQs Accordion Container */
.service-faqs .accordion-container{
  margin-top: 1rem;
}