/* Why Us Box Container */
.why-us .box-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; 
  gap: 2rem;
} 
 
/* Why Us Content */
.why-us .content{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 42rem;
      flex: 1 1 42rem;
}

/* Why Us Heading */
.why-us .heading{
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  padding-bottom: 0;
}

/* Why Us Heading Text */
.why-us .content h3{
  font-size: 4rem;
  font-weight: 600;
  color: var(--black);
  line-height: 1.2;
  padding-bottom: 1rem;
}

/* Why Us Description */
.why-us .content p{
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: var(--grey);
  line-height: 1.6;
  padding-bottom: 1.5rem;
}

/* Why Us List */
.why-us ul{
  list-style-type: none;
}

/* Why Us List Item */
.why-us ul li{
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

/* Why Us List Item Icon */
.why-us ul li .icon{
  height: 2rem;
  width: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.8rem;
  color: var(--main-color);
}

/* Why Us Image Container */
.why-us .image{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 42rem;
      flex: 1 1 42rem;
  padding: 4rem;
  padding-left: 0;
  background: -webkit-linear-gradient(left, var(--white) 70%, var(--main-color) 30%);
  background:         linear-gradient(to right, var(--white) 70%, var(--main-color) 30%);
}

/* Why Us Image */
.why-us .image img{
  height: 45rem;
  width: 100%;
  object-fit: cover;
}