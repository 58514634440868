/* FAQ section */
.faq {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;  
    gap: 2rem; 
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
}

/* FAQ Accordion container */
.faq .accordion-container{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 42rem;
        flex: 1 1 42rem;
}

/* FAQ Image Container */
.faq .image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
    height: 50rem;
    border-radius: var(--border-radius);
    overflow: hidden;
}

/* FAQ Image */
.faq .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}