/* Product container */
.product-item{
  overflow: hidden;
  -webkit-transition: all 1s ease;  
  -moz-transition: all 1s ease;  
  -o-transition: all 1s ease;  
  transition: all 1s ease; 
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  cursor: pointer;
  border: 0.1rem solid rgba(0, 0, 0, 0.05); 
}    

.product-item .options{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; 
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  gap: 1rem;
  opacity: 0;
}

.product-item:hover .options{
  opacity: 1;
}

.product-item .options a{
  height: 4.5rem;
  width: 4.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; 
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background: var(--main-color);
  color: var(--white);
  cursor: pointer;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.product-item .options a .icon{ 
  font-size: 1.6rem;
}

.product-item .options a:hover{
  background-color: var(--secondary-color);
}
  
/* Image container */ 
.product-item .image {  
  overflow: hidden;
  position: relative;
}

/* Product image */
.product-item .image img{
  width: 100%;
  height: 25rem;
  object-fit: cover;
  overflow: hidden;
}

/* Product content */
.product-item .content {
  padding: 1rem 0;
  text-align: center;
}

/* Product name */
.product-item h3 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--secondary-color);
  text-transform: uppercase;
}

/* Product name, On Hover */
.product-item h3:hover {
  color: var(--main-color);
}

/* Product price */
.product-item .price { 
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--main-color);
}

/* Product Discounted price */
.product-item .price span {
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: line-through;
  color: var(--grey);
  padding-left: 0.2rem;
} 
 
/* Product rating */
.product-item .rating {
  padding-top: 0.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1rem;
}

/* Product Rating icon */
.product-item .rating .icon {
  color: var(--main-color);
  font-size: 2rem;
}

/* Product Rating text */
.product-item .rating h5 {
  font-size: 1.5rem;
  color: var(--black);
} 