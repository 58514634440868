/* Blog Card */
.blog-item{
  position: relative;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}
  
/* Blog Image Container */ 
.blog-item .image{  
  height: 25rem; 
  position: relative;  
}

/* Blog Image */
.blog-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
} 

/* Blog Publication Date */
.blog-item .date{
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  height: 9rem;
  width: 9rem;
  font-size: 1.9rem;
  padding: 3rem;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: var(--white);
  background-color: var(--main-color);
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

/* Blog Content */
.blog-item .content{
  padding: 1.5rem;
  background-color: var(--white);
}

/* Blog Category */
.blog-item .category{
  display: inline-block;
  font-size: 1.6rem;
  color: var(--white);
  background-color: var(--main-color);
  padding: 0.7rem 1.2rem;
}

/* Blog Category, On Hover */
.blog-item .category:hover{
  background-color: var(--secondary-color);
}

/* Blog Heading */
.blog-item .content .main-heading{
  display: block;
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--black);
  line-height: 1.3;
  padding: 1rem 0;
}

/* Blog Heading, On Hover */
.blog-item .content .main-heading:hover{
  color: var(--main-color);
}

/* Blog Admin */
.blog-item h3{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  border-top: 0.15rem solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
}

/* Blog Admin Icon */
.blog-item h3 .icon{
  font-size: 3rem;
  color: var(--main-color);
}

/* Blog Admin Text */
.blog-item h3 span{
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--grey);
}