/* Testimonials section */
.testimonials{
    overflow: hidden;
}  

/* Testimonial slider container */
.testimonial-slider{ 
    overflow: hidden; 
    position: relative;  
}  
 
/* Styling for the pagination bullet */
.testimonial-slider .swiper-pagination-bullet{ 
    background: var(--secondary-color) !important;
    opacity: 0.9;
}
 
/* Styling for the active pagination bullet */
.testimonial-slider .swiper-pagination-bullet-active{
    width: 6rem;
    background: var(--main-color) !important;
    opacity: 0.9;
}
 
/* Testimonial slider swiper container */
.testimonial-slider .swiper{
    overflow: hidden;   
    padding-bottom: 6rem;
}

/* Testimonial item */
.testi-item{
    position: relative;
    padding: 3rem;
    background-color: var(--main-color);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/* Testimonial Image Container */
.testi-item .image{
    position: relative;
    height: 8rem;
    width: 8rem;
}

/* Testimonial image */
.testi-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    border: 0.5rem solid var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/* Testimonial quote icon */
.testi-item .quote-left{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--white);
    background-color: var(--secondary-color);
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

/* Testimonial item paragraph */
.testi-item p{
    font-size: 1.5rem;
    line-height: 1.6;
    padding: 2rem 0;
    color: var(--white);
}

/* Testimonial item name */
.testi-item h4{
    font-size: 2.2rem;
    color: var(--white);
}

/* Testimonial item title */
.testi-item h6{
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--white);
}

/* Testimonial quote right icon */
.testi-item .quote-right{
    position: absolute;
    bottom: 2rem;
    right: 3rem;
    font-size: 8rem;
    color: var(--white);
}

/* Testimonials section Heading (On the homepage) */
.homepage .testimonials .heading h2{
    color: var(--white);
}

/* Testimonials section (On the homepage) */
.homepage .testimonials{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Testimonials.jpg");
    background:         linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Testimonials.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
} 

/* Styling for the pagination bullet (On the homepage) */
.homepage .testimonial-slider .swiper-pagination-bullet{ 
    background: #999 !important;
    opacity: 0.7;
}

/* Styling for the active pagination bullet (On the homepage) */
.homepage .testimonial-slider .swiper-pagination-bullet-active{
    background: var(--main-color) !important;
    opacity: 0.9;
}