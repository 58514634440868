/* Checkout Heading */
.checkout .heading{
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}
 
/* Checkout section Heading title */
.checkout .heading h2{
    font-size: 3.5rem; 
}   

/* Checkout item */
.checkout-item{  
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
    border: var(--border);
    background-color: var(--white);
    margin-bottom: 1.5rem;
    padding: 2rem;
}

/* Checkout section, Box-1 section */
.checkout .box-1,
.checkout .box-2{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap; 
        flex-wrap: wrap;
    gap: 1.5rem;
}

/*----- 1- Payment Method -----*/
/* Payment method item */
.payment-methods .item {
    width: 100%;
    margin-bottom: 1.5rem;
}

/* Payment method label */
.payment-methods .item label {
    cursor: pointer;
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-color);
    text-transform: uppercase;
}

/* Payment method input */
.payment-methods .item input {
    margin-right: 0.5rem;
}

/* Selected/Highlighted payment method label */
.payment-methods .item input:checked + label,
.payment-methods .item label:hover {
    color: var(--main-color);
}

/* Radio Input Styles */
input[type="radio"] {
	appearance: none;
	width: 1.6rem;
	height: 1.6rem;
	border: 0.2rem solid var(--secondary-color);
	border-radius: 50%;
	background-clip: content-box;
	padding: 0.2rem;
}

/* Checked Radio Input Styles */
input[type="radio"]:checked {
	background-color: var(--main-color);
    border-color: var(--main-color);
}

/* Payment method body */
.payment-methods .item .payment-body {
    display: none;
}

/* Payment method body paragraph */
.payment-methods .item .payment-body p {
    font-size: 1.6rem;
    line-height: 2; 
    color: var(--grey);
    padding-top: 1rem;
}

/* Show payment method body when input is checked */
.payment-methods .item > input:checked ~ .payment-body {
    display: block;
}

/* Active payment method body */
.payment-body.active {
    display: block;
}

/* Checkout Button Container */
.checkout .btn-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

/* Checkout section Button */
.checkout .btn{
    width: auto;
    text-align: center;
}