/* Pricing Container */
.pricing .box-container{
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  gap: 1rem;
}  

/* Pricing Item */ 
.price-item{ 
  background-color: var(--white); 
  border: var(--border); 
  overflow: hidden;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  text-align: left;
  padding: 4rem;
}

/* Styling for the second Pricing Item */
.price-item:nth-child(2){ 
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* Pricing Item Heading */
.price-item h3{
  color: var(--black);
  font-size: 3.5rem;
  font-weight: 500;
}

/* Styling for the second Pricing Item Heading */
.price-item:nth-child(2) h3{
  color: var(--white);
}

/* Pricing Item Description */
.price-item p{
  color: var(--grey);
  font-size: 1.5rem;
  font-weight: 400;
}

/* Styling for the second Pricing Item Description */
.price-item:nth-child(2) p{
  color: var(--white);
}

/* Pricing */
.price-item .price{
  color: var(--secondary-color);
  font-size: 6rem;
  font-weight: 400;
  padding-top: 0.5rem;
}

/* Styling for the second Pricing */
.price-item:nth-child(2) .price{
  color: var(--white);
}
  
/* Pricing Span */
.price-item .price span{
  font-size: 2rem;
  font-weight: normal;
  color: var(--grey);
}

/* Styling for the second Pricing Span */
.price-item:nth-child(2) .price span{
  color: var(--white);
}
  
/* Pricing Item List */
.price-item ul{ 
  list-style: none;
  margin: 2rem 0;
  padding: 1.5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

/* Styling for the second Pricing Item List */
.price-item:nth-child(2) ul{ 
  border-color: rgba(255, 255, 255, 0.2);
}
  
/* Pricing Item List Items */
.price-item ul li{
  padding: 0.5rem 0;
  font-size: 1.6rem;
  color: var(--grey);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  gap: 0.7rem;
}

/* Styling for the second Pricing Item List Items */
.price-item:nth-child(2) ul li{
  color: #f7f7f7;
}

/* Pricing Item List Item Icon */
.price-item ul li .icon{
  color: var(--main-color);
  font-size: 1rem;
}