/* Header Section */
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
} 
  
/* Header 1 */
.header .header-1{
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 5%;
  background-color: var(--main-color);
}

/* Header-1, On Header Active */
.header.active .header-1{
  display: none;
}

/* Header contacts */
.header .header-contacts{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 3rem;
  list-style-type: none;
}

/* Header contact */
.header .header-contacts li{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  gap: 1rem;
  font-size: 1.6rem;
  color: var(--white);
}

/* Header contact Text */
.header .header-contacts span{
  text-transform: none;
}

/* Social contacts */
.header .social-contacts{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  list-style-type: none;
}

/* Social contact Link */
.header .social-contacts a{
  height: 3rem;
  width: 3rem;
  font-size: 1.6rem;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Social contact Link, On Hover */
.header .social-contacts a:hover{
  color: var(--secondary-color);
}

/* Header-2 */
.header .header-2{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 8rem;
  padding: 0rem 5%;
  background: var(--secondary-color);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

/* Container Inside Header 2 */
.header .container{
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2rem;
}

/* Header Navbar */
.header .navbar{
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  gap: 1.8rem;
}

/* Header Icon Container */
.header .icon-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
} 

/* Header Icon Link */
.header .icon{
  font-size: 1.8rem;
  color: var(--white);
  cursor: pointer;
}

/* Header Icon Link, On Hover */
.header .icon:hover{
  color: var(--main-color);
}

/* Header Menu Button */
.header .menu-btn{
  display: none;
}

/* Header Cart Link */
.header .cart-icon {
  position: relative;
}

/* Header Cart Link Badge */
.header .cart-icon .badge {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center; 
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: var(--main-color);
  color: var(--white);
  border-radius: 50%;
  font-size: 1.2rem;
}

/* Mobile Menu */
.mobile-menu{
  display: none;
}

/* Header Search Container */
.header .search-container{
  position: absolute;
  top: 100%;
  right: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2rem;
  border-radius: 0.5rem;
  background: var(--white);
  width: 50rem;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transition: -webkit-transform 0.5s linear;
          transition: -webkit-transform 0.5s linear;
          transition: transform 0.5s linear;
          transition: transform 0.5s linear, -webkit-transform 0.5s linear;
}

/* Active Search Container */
.header .search-container.active{
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

/* Search Input Field */
.header .search-container input{
  width: 100%;
  height: 100%;
  background: transparent;
  color: var(--black);
  font-size: 1.8rem;
  padding: 0.5rem;
  text-transform: none;
}

/* Search Input Placeholder */
.header .search-container input::-webkit-input-placeholder{
  text-transform: capitalize;
}

.header .search-container input::-moz-placeholder{
  text-transform: capitalize;
}

.header .search-container input:-ms-input-placeholder{
  text-transform: capitalize;
}

.header .search-container input::placeholder{ 
  text-transform: capitalize;
}
 
/* Search Button */
.header .search-container button{
  background-color: transparent;
}

/* Search Icon */
.header .search-container .icon{
  color: var(--secondary-color);
  font-size: 2rem;
}

/* Search Icon, On Hover */
.header .search-container .icon:hover{
  color: var(--main-color);
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 991px) {

  /* Header Menu Button (Visible on Mobile) */
  .header .menu-btn {
    display: block; 
  } 

  /* Mobile Menu (Visible on Mobile) */
  .mobile-menu {
    display: block;
  }

  /* Header Navbar (Hidden on Mobile) */
  .header .navbar {
    display: none;
  }
}  