/* Sponsor Slider */
.sponsor-slider{  
  padding-top: 2rem;
}

/* Sponsor Item */
.sponsor-item{
  padding: 2rem; 
} 
 
/* Sponsor Image */
.sponsor-slider img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}